module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pacific Pain & Wellness Group | On-Site Ketamine Infusions","short_name":"Pacific Pain & Wellness | On-Site Ketamine Infusions","start_url":"/","lang":"eng","background_color":"#ffffff","theme_color":"#000000","display":"browser","icon":"src/images/favicon-ppwg.png","icons":[{"src":"src/images/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"383fd1b7f7b114628f322e1afd6edf0a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VK55BK4WPF","AW-11335981586"],"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"5037797342981559"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
